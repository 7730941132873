h1, h2, h3, h4, h5 {
  line-height: 1.4;
}

h1 {
  margin-bottom: 3.5rem;
  font-weight: 400;
}

h2 {
  margin-bottom: 2rem;
  font-weight: 600;
}

img {
  width: 100%;
  height: auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Nastaliq Urdu Draft', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.question-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
  font-family: 'Noto Nastaliq Urdu Draft', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.question-container-seperate-answer-line {
  flex-direction: column;
  width: 100%;
}

.rtl-question-container {
  direction: rtl;
}

.question-field {
  min-width: 250px;
  font-family: 'Noto Nastaliq Urdu Draft', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  margin-right: 1rem;
}

.question-field-rtl {
  margin-left: 1rem;
  margin-right: 0;
}

.short-question-field {
  margin-left: 0.5rem;
  font-family: 'Noto Nastaliq Urdu Draft', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

@media only screen and (max-width: 600px) {
  .question-container {
    margin: 2rem 0;
  }
}

.nastaliq {
  font-family: 'Noto Nastaliq Urdu Draft';
  font-size: 1.1rem;
}

.answer-field {
  flex-grow: 1;
  min-width: 30px;
  font-family: 'Noto Nastaliq Urdu Draft', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  direction: rtl;
}

.clickable:hover {
  cursor: pointer;
}

.unstyled-link {
  text-decoration: none;
  color: inherit;
}

